// Web app's Firebase configuration:
const firebaseConfig = {
    apiKey: "AIzaSyBRdfbE-77uG8fl-Jq5fC8PRzpPcwX38vE",
    authDomain: "naichuk-550a1.firebaseapp.com",
    projectId: "naichuk-550a1",
    storageBucket: "naichuk-550a1.appspot.com",
    messagingSenderId: "49074552731",
    appId: "1:49074552731:web:d19e1eb7e1ebc283c22b3e"
};

// Current theme settings
let currentWebsiteTheme = localStorage.preferedTheme;

// Populating common elements with the content
document.querySelector('header').innerHTML = `
<a href="${window.location.origin}">A.Naichuk</a>
<nav>
    <a href="./about.html">about</a>
    <a href="./contact.html">contact</a>
    <a href="#" id="themeSwitchButton" data-state="dark">☀dark</a>
</nav>
`;
document.querySelector('footer').innerHTML = `
<div class="footer-copyright">
<a href="${window.location.origin}">A.Naichuk</a>
<h6>Crafted with love.</h6>
</div>

<nav class="desktop-only">
<a href="./about.html">about</a>
<a href="./contact.html">contact</a>
<h6>|</h6>
<a href="https://www.linkedin.com/in/andrew-naichuk" target="_blank">LN</a>
<a href="https://github.com/Andrew-Naichuk" target="_blank">GH</a>
<a href="https://www.facebook.com/a.naichuck" target="_blank">FB</a>
<a href="https://t.me/kelewin" target="_blank">TG</a>
<a href="mailto:andrew.naychuk@gmail.com">andrew.naychuk@gmail.com</a>
</nav>


<div class="mobile-nav">
<a href="./about.html">about</a>
<a href="./contact.html">contact</a>
<div class="links-row">
    <a href="https://www.linkedin.com/in/andrew-naichuk" target="_blank">LN</a>
    <a href="https://github.com/Andrew-Naichuk" target="_blank">GH</a>
    <a href="https://www.facebook.com/a.naichuck" target="_blank">FB</a>
    <a href="https://t.me/kelewin" target="_blank">TG</a>
</div>
<a href="mailto:andrew.naychuk@gmail.com">andrew.naychuk@gmail.com</a>
</div>
`;

const themeSwitchButton = document.querySelector('#themeSwitchButton');
const currentThemeSettings = document.querySelector('#currentThemeSettings');

// Checking theme preference
checkPreferedTheme();

// Theme switching functionality
themeSwitchButton.addEventListener('click', ()=>{
    switchTheme();
});

// Preview window functionality
document.querySelector('body').addEventListener('mousemove', (event)=>{
    const previewWindow = document.querySelector('#previewWindow');
    const positionX = event.clientX + 10;
    const positionY = event.clientY + 10;
    if (previewWindow) {
        if (event.target.dataset.type) {
            previewWindow.style.opacity = '1';
            previewWindow.style.top = `${positionY}px`;
            previewWindow.style.left = `${positionX}px`;
            if (event.target.dataset.type === 'projectLink') {
                previewWindow.innerHTML = '<h3>Case study comming soon</h3>'
            };
            if (event.target.dataset.type === 'pageLink') {
                previewWindow.innerHTML = `<h4>${event.target.dataset.label}</h4>`
            };
        } else {
            previewWindow.style.opacity = '0';
        };
    };
});

// Contact form functionality
const submitMessageButton = document.querySelector('#submitMessageButton');
if (submitMessageButton) {
    submitMessageButton.addEventListener('click', async function(){
        // Checking the form is complete
        const contactFormNameField = document.querySelector('#contactFormName');
        const contactFormEmailField = document.querySelector('#contactFormEmail');
        const contactFormMessageField = document.querySelector('#contactFormMessage');
        if (contactFormNameField.value.length < 1) {
            showMessage('error', 'Please, let me know your name', 2500);
            return;
        };
        if (contactFormEmailField.value.includes('@') === false) {
            showMessage('error', 'Looks like the email is not correctly formated', 2500);
            return;
        };
        if (contactFormEmailField.value.includes('.') === false) {
            showMessage('error', 'Looks like the email is not correctly formated', 2500);
            return;
        };
        if (contactFormEmailField.value.length < 6) {
            showMessage('error', 'Looks like the email is not correctly formated', 2500);
            return;
        };
        if (contactFormMessageField.value.length < 5) {
            showMessage('error', 'Please, fill out the message field', 2500);
            return;
        };
        // Preparing fields values for db write
        const emailText = contactFormEmailField.value.replace(/</g, '(').replace(/>/g, ')');
        const nameText = contactFormNameField.value.replace(/</g, '(').replace(/>/g, ')');
        const messageText = contactFormMessageField.value.replace(/</g, '(').replace(/>/g, ')');
        // Saving message to db
        try {
            // Initialize Firebase
            firebase.initializeApp(firebaseConfig);
            const db = firebase.firestore();

            const timestamp = Date.now();
            const timestampText = `${new Date(timestamp).toDateString()} -- ${new Date(timestamp).toLocaleTimeString()}`;
            const newMessage = {
                "to": ['andrew.naychuk@gmail.com'],
                "message": {
                subject: '📩 New message submited on portfolio website!',
                html: `
                    <html>
                        <body>
                        <style>
                        * {
                        font-family: sans-serif;
                        margin: 0;
                        }
                        body {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        align-items: center;
                        }
                        .message {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        margin-top: 48px;
                        padding: 40px 32px;
                        gap: 24px;
                        max-width: 500px;
                        background-color: #fff;
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
                        }
                        hr {
                        width: 100%;
                        border: solid, #e8e8e8;
                        opacity: 0.3;
                        }
                        </style>
                            <div class="message">
                            <h1>New message from portfolio</h1>
                            <hr>
                            <h5 id="messageTimestamp">${timestampText}</h5>
                            <hr>
                            <h3 id="messageName">${nameText}</h3>
                            <h3 id="messageEmail">${emailText}</h3>
                            <h3 id="messageText">${messageText}</h3>
                            </div>
                        </body>
                    </html>
                `,
                },
            };
            await db.collection("contactMessages").doc(`${timestamp}`).set(newMessage);
            showMessage('success', 'Message sent! Thanks 👋', 3000);
            // Reseting inputs to default
            contactFormEmailField.value = '';
            contactFormNameField.value = '';
            contactFormMessageField.value = '';
        } catch (error) {
            showMessage('error', error, 3000);
        };
    });
};

// Function declarations
function checkPreferedTheme(){
    if (currentWebsiteTheme === 'dark') {
        currentWebsiteTheme = 'light';
        switchTheme();
        return;
    };
    if (currentWebsiteTheme === 'light') {
        currentWebsiteTheme = 'dark';
        switchTheme();
        return;
    };
    if (!currentWebsiteTheme) {
        currentWebsiteTheme = 'light';
        switchTheme();
        return;
    };
};

function switchTheme(){
    const darkThemeSettings = `\n:root {\n--current-page-background: var(--page-background-dark);\n--current-text: var(--text-dark);\n--current-text-faded: var(--faded-text-dark);\n--current-tag-background: var(--tag-background-dark);\n--current-accent: var(--accent-dark);\n--current-accent-hover: var(--accent-hover-dark);\n}\n`;
    const lightThemeSettings = `\n:root {\n--current-page-background: var(--page-background-light);\n--current-text: var(--text-light);\n--current-text-faded: var(--faded-text-light);\n--current-tag-background: var(--tag-background-light);\n--current-accent: var(--accent-light);\n--current-accent-hover: var(--accent-hover-light);\n}\n`;
    if (currentWebsiteTheme == 'dark') {
        localStorage.preferedTheme = 'light';
        currentWebsiteTheme = 'light';
        themeSwitchButton.innerText = 'dark';
        currentThemeSettings.innerHTML = lightThemeSettings;
    } else {
        localStorage.preferedTheme = 'dark';
        currentWebsiteTheme = 'dark';
        themeSwitchButton.innerText = 'light';
        currentThemeSettings.innerHTML = darkThemeSettings;
    };
};

// Showing notification
// Supported types: success, fail
function showMessage(type, message, duration){
    const dialog = document.querySelector('dialog');
    const notificationMessage = document.querySelector('#notificationMessage');
    notificationMessage.innerText = message;
    dialog.classList.add(type);
    dialog.showModal();
    dialog.classList.remove('slide-out-blurred-right');
    dialog.classList.add('slide-in-bottom');
    setTimeout(()=>{
        dialog.classList.remove('slide-in-bottom');
        dialog.classList.add('slide-out-blurred-right');
    }, duration - 1000);
    setTimeout(()=>{
        dialog.classList.remove(type);
        dialog.close();
    }, duration);
};